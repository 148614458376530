@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  background-color: #00322f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
}

a:link,
a:visited {
  background-color: transparent;
  color: rgb(184, 127, 82);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  text-align: center;
}

a:hover,
a:active {
  color: black;
}

button {
  background-color: #1976d2;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: medium;
  transition: 0.5s all;
  color: white;
}

button:hover {
  background-color: #064e97;
}

.inputAmount {
  border-radius: 5px;
  width: 96%;
  height: 40px;
  font-size: medium;
  border: 1px solid black;
  margin-bottom: 20px;
  padding: 0px 15px;
}

.inputAmount:hover {
  border: 2px solid black;
  background-color: #d8cfc2;
}

p {
  text-align: center;
}

.stakeTag {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.radioSelect {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
  font-size: 20px;
  margin-top: 0;
}

.radioSelect input {
  /* height: 1.1rem;
  width: 1.1rem; */
}
.wrapper {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
}

